var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "div",
        [
          _c("gov-heading", { attrs: { size: "l" } }, [
            _vm._v(_vm._s(_vm._f("ucfirst")(_vm.title)))
          ]),
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "one-half" } },
                [
                  _c("gov-body", [_vm._t("intro")], 2),
                  _c("gov-section-break", { attrs: { size: "l" } }),
                  _vm._t("default")
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }